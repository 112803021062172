export class Cricket {
  constructor(teams, matches, settings) {
    var newSettings = settings.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );

    this._POINTS_PER_WIN = newSettings["points_per_win"];
    this._POINTS_PER_NRT = newSettings["points_per_nrt"];
    this._POINTS_PER_LOSS = newSettings["points_per_loss"];
    this._BALLS_PER_OVER = newSettings["balls_per_over"];
    this._OVERS_PER_INNING = newSettings["overs_per_inning"];
    this._WICKETS_PER_INNING = newSettings["wickets_per_inning"];
    this._matches = matches;
    this._teams = teams;
  }

  generateInitialPointTable() {
    return this._teams.map((teamId) => ({
      id: teamId,
      team: teamId,
      played: 0,
      won: 0,
      loss: 0,
      points: 0,
      nrt: 0,
      for: { runs: 0, overs: 0 },
      against: { runs: 0, overs: 0 },
      nrr: 0,
    }));
  }

  generateComputedFields(currentPointTable) {
    return currentPointTable.map((pointEntry) => ({
      ...pointEntry,
      nrr: this._calculateNRR(pointEntry.for, pointEntry.against),
    }));
  }

  sortPointTable(currentPointTable) {
    return currentPointTable.sort(
      (a, b) => b.points - a.points || b.nrr - a.nrr || a.played - b.played
    );
  }

  _calculateNRR = (forData, againstData) => {
    const { runs: forRuns, overs: forOvers } = forData;
    const { runs: againstRuns, overs: againstOvers } = againstData;

    if (forOvers === 0 || againstOvers === 0) {
      return 0;
    }

    const forOversParts = forOvers.toFixed(1).toString().split(".");
    const againstOversParts = againstOvers.toFixed(1).toString().split(".");
    const forPart =
      forRuns /
      (parseInt(forOversParts[0]) +
        parseFloat(forOversParts[1] / this._BALLS_PER_OVER));
    const againstPart =
      againstRuns /
      (parseInt(againstOversParts[0]) +
        parseFloat(againstOversParts[1] / this._BALLS_PER_OVER));

    const nrr = forPart - againstPart ? forPart - againstPart : 0;

    return nrr;
  };

  addTeamPoints(
    teamID,
    teamScore,
    opponentTeamID,
    opponentTeamScore,
    result,
    winner,
    meta,
    teamCurrentPoints
  ) {
    const { runs, overs, wickets } = this._getTeamScore(
      teamID === meta.bat_first,
      teamScore,
      result,
      meta
    );

    const {
      runs: otherTeamRuns,
      overs: otherTeamOvers,
      wickets: otherTeamWickets,
    } = this._getTeamScore(
      opponentTeamID === meta.bat_first,
      opponentTeamScore,
      result,
      meta
    );

    const won = result === "RESULT" && teamID === winner ? 1 : 0;
    const loss = result === "RESULT" && teamID !== winner ? 1 : 0;
    const nrt = result === "DRAW" || result === "NO_RESULT" ? 1 : 0;

    const pointPerMatch =
      won * this._POINTS_PER_WIN +
      loss * this._POINTS_PER_LOSS +
      nrt * this._POINTS_PER_NRT;

    const teamNewPoints = {
      ...teamCurrentPoints,
      played: (teamCurrentPoints.played += 1),
      won: (teamCurrentPoints.won += won),
      loss: (teamCurrentPoints.loss += loss),
      nrt: (teamCurrentPoints.nrt += nrt),
      points: (teamCurrentPoints.points += pointPerMatch),
      for: {
        runs: (teamCurrentPoints.for.runs += runs),
        overs: this._calculateOvers(
          teamCurrentPoints.for.overs,
          wickets === this._WICKETS_PER_INNING ? this._OVERS_PER_INNING : overs
        ),
      },
      against: {
        runs: (teamCurrentPoints.against.runs += otherTeamRuns),
        overs: this._calculateOvers(
          teamCurrentPoints.against.overs,
          otherTeamWickets === this._WICKETS_PER_INNING
            ? this._OVERS_PER_INNING
            : otherTeamOvers
        ),
      },
    };

    return teamNewPoints;
  }

  _getTeamScore(battingFirstTeam, score, result, meta) {
    if (result == "NO_RESULT") {
      return { runs: 0, overs: 0, wickets: 0 };
    } else if (meta.target && meta.bat_first && meta.max_overs) {
      if (battingFirstTeam) {
        return { ...score, runs: meta.target - 1, overs: meta.max_overs };
      } else {
        return { ...score, overs: meta.max_overs };
      }
    } else {
      return { ...score };
    }
  }

  _calculateOvers = (currentOvers, newOvers) => {
    const [currentOver, currentBalls] = currentOvers
      .toFixed(1)
      .toString()
      .split(".");
    const [newOver, newBalls] = newOvers.toFixed(1).toString().split(".");

    const balls = parseInt(currentBalls) + parseInt(newBalls);
    const overs = parseInt(currentOver) + parseInt(newOver);
    const sum =
      overs +
      parseInt(balls / this._BALLS_PER_OVER) +
      (balls % this._BALLS_PER_OVER) * 0.1;

    return sum;
  };
}
