import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Loader,
  Message,
  Popup,
} from "semantic-ui-react";
import styled from "styled-components";

import {
  fetchTournaments,
  deleteTournament,
  selectTournaments,
  selectTotalPages,
  selectFetchStatus,
  selectFetchError,
} from "./tournamentSlice";

const TableContainer = styled.div`
  margin: 20px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledTable = styled(Table)`
  margin-top: 1rem;
`;

const TournamentListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tournaments = useSelector(selectTournaments);
  const totalPages = useSelector(selectTotalPages);
  const status = useSelector(selectFetchStatus);
  const error = useSelector(selectFetchError);

  const [open, setOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);

  useEffect(() => {
    dispatch(fetchTournaments({ page: 1, per_page: 5 }));
  }, [dispatch]);

  const handlePageChange = (e, { activePage }) => {
    dispatch(fetchTournaments({ page: activePage, per_page: 10 }));
  };

  const handleDelete = (id) => {
    dispatch(deleteTournament(id));
    setOpen(false);
  };

  return (
    <TableContainer>
      {status === "loading" && <Loader active inline="centered" />}
      {error && <Message error header="Error" content={error} />}
      <HeaderWrapper>
        <h1>Tournaments</h1>
        <Button color="blue" onClick={() => navigate("create")}>
          <Icon name="plus" /> New Tournament
        </Button>
      </HeaderWrapper>
      <StyledTable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tournament Name</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tournaments.map((tournament) => (
            <Table.Row key={tournament.id}>
              <Table.Cell>{tournament.name}</Table.Cell>
              <Table.Cell>{tournament.date}</Table.Cell>
              <Table.Cell>
                <Button
                  icon
                  color="blue"
                  onClick={() => navigate(`${tournament.id}`)}
                >
                  <Icon name="eye" />
                </Button>
                <Button
                  icon
                  color="green"
                  onClick={() => navigate(`${tournament.id}/edit`)}
                >
                  <Icon name="edit" />
                </Button>
                <Popup
                  content={
                    <>
                      <p>Are you sure you want to delete this tournament?</p>
                      <Button
                        color="red"
                        content="Yes"
                        onClick={() => handleDelete(toBeDeleted)}
                      />
                      <Button content="No" onClick={() => setOpen(false)} />
                    </>
                  }
                  on="click"
                  open={open === tournament.id}
                  onClose={() => setOpen(null)}
                  onOpen={() => {
                    setToBeDeleted(tournament.id);
                    setOpen(tournament.id);
                  }}
                  trigger={
                    <Button icon color="red">
                      <Icon name="trash" />
                    </Button>
                  }
                />
                <Button
                  icon
                  color="yellow"
                  onClick={() => navigate(`/tournament/${tournament.uuid}/`)}
                >
                  <Icon name="share alternate" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </StyledTable>
      <Pagination
        totalPages={totalPages}
        onPageChange={handlePageChange}
        boundaryRange={0}
        defaultActivePage={1}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={1}
      />
    </TableContainer>
  );
};

export default TournamentListPage;
