import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "../../api/api";
import { fetchTournamentById } from "../../api/apiActions";

const TOURNAMENT_END_POINT = `/tournaments`;

export const getTeamsByTournamentId = createAsyncThunk(
  "team/getTeamsByTournamentId",
  async (tournamentId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `${TOURNAMENT_END_POINT}/${tournamentId}/teams/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveTeamsByTournamentId = createAsyncThunk(
  "team/saveTeamsByTournamentId",
  async ({ tournamentId, teams }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${TOURNAMENT_END_POINT}/${tournamentId}/teams/`,
        teams
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState: {
    teams: [],
    status: "idle",
    error: null,
  },
  reducers: {
    createTeam: (state, action) => {
      const { team } = action.payload;
      state.teams.push(team);
    },
    editTeam: (state, action) => {
      const { teamId, updatedTeam } = action.payload;
      const teamIndex = state.teams.findIndex((team) => team.id === teamId);
      state.teams[teamIndex] = updatedTeam;
    },
    deleteTeam: (state, action) => {
      const { teamId } = action.payload;
      state.teams = state.teams.filter((team) => team.id !== teamId);
    }
  },
  extraReducers: {
    [getTeamsByTournamentId.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [getTeamsByTournamentId.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.teams = action.payload;
    },
    [getTeamsByTournamentId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [saveTeamsByTournamentId.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [saveTeamsByTournamentId.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.teams = action.payload;
    },
    [saveTeamsByTournamentId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchTournamentById.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchTournamentById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { teams } = action.payload;
      state.teams = teams;
    },
    [fetchTournamentById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const { createTeam, editTeam, deleteTeam } = teamSlice.actions;
export const selectTeams = (state) => state.team.teams;
export const getTeamById = (state, teamId) => {
  return state.team.teams.find((team) => team.id === teamId);
};
export default teamSlice.reducer;
