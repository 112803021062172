import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchTournamentById } from "../api/apiActions";
import { ActionButton } from "../components/CustomComponents/Buttons";
import TournamentView from "../components/TournamentView";
import { useNavigate } from "react-router-dom";

const PublicTournamentView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchTournamentById({ id, isPrivate: false }));
  }, [dispatch, id]);

  return (
    <Wrapper>
      <Topbar>
        <ActionButton onClick={() => navigate(-1)}>Back</ActionButton>
      </Topbar>
      <TournamentView />
    </Wrapper>
  );
};

export default PublicTournamentView;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const Topbar = styled.nav`
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  height: 64px;
  padding: 24px 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
`;
