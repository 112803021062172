import { configureStore } from "@reduxjs/toolkit";

import teamReducer from "../features/team/teamSlice";
import groupReducer from "../features/group/groupSlice";
import matchReducer from "../features/match/matchSlice";
import settingsReducer from "../features/setting/settingsSlice";
import tournamentsReducer from "../features/tournament/tournamentSlice";

import authReducer from "../features/auth/authSlice";

export const store = configureStore({
  reducer: {
    team: teamReducer,
    group: groupReducer,
    match: matchReducer,
    settings: settingsReducer,
    tournament: tournamentsReducer,
    auth: authReducer,
  },

  devTools: true,
});
