import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createMatch, editMatch, selectMatchSummaryMode } from "./matchSlice";
import {
  StyledForm,
  FormGroup,
  Label,
  SubmitButton,
  Select,
  Input,
} from "../../components/CustomForm";
import { Button } from "semantic-ui-react";

import { selectGroups } from "../group/groupSlice";
import { selectTeams } from "../team/teamSlice";
import styled from "styled-components";

const MatchStyledForm = styled(StyledForm)`
  max-width: 700px;
`;

const TeamSelect = styled(Select)`
  width: 150px;
`;

const FormGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-left: 1rem;
`;

const stats = { runs: 0, overs: 0, wickets: 0 };
const defaultValues = {
  team_1_score: { ...stats },
  team_2_score: { ...stats },
  status: "UP_COMING",
  result: null,
  meta: { toss: "" },
  winner: null,
};
const MatchForm = ({ selectedMatch }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues,
  });

  const teams = useSelector(selectTeams); // Get teams from Redux state
  const groups = useSelector(selectGroups); // Get groups from Redux state
  const summaryMode = useSelector(selectMatchSummaryMode) === "view";

  const team1 = watch("team_1");
  const team2 = watch("team_2");

  useEffect(() => {
    if (selectedMatch) {
      setValue("team_1", selectedMatch.team_1);
      setValue("team_2", selectedMatch.team_2);
      setValue("group", selectedMatch.group);
      setValue("meta.toss", selectedMatch.meta.toss);
      setValue("meta.bat_first", selectedMatch.meta.bat_first);
      setValue("meta.target", selectedMatch.meta.target);
      setValue("meta.max_overs", selectedMatch.meta.max_overs);
      setValue("winner", selectedMatch.winner);

      setValue("team_1_score.runs", selectedMatch.team_1_score.runs);
      setValue("team_1_score.overs", selectedMatch.team_1_score.overs);
      setValue("team_1_score.wickets", selectedMatch.team_1_score.wickets);

      setValue("team_2_score.runs", selectedMatch.team_2_score.runs);
      setValue("team_2_score.overs", selectedMatch.team_2_score.overs);
      setValue("team_2_score.wickets", selectedMatch.team_2_score.wickets);

      setValue("status", selectedMatch.status);
      setValue("result", selectedMatch.result);
      setValue("date", selectedMatch.date);
  
    }
  }, [selectedMatch, setValue]);

  const onSubmit = (data) => {
    if (selectedMatch) {
      dispatch(
        editMatch({
          matchId: selectedMatch.id,
          updatedMatch: { ...data, id: selectedMatch.id },
        })
      );
    } else {
      dispatch(createMatch({ match: { ...data, id: Math.random() } }));
    }
    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <>
      <MatchStyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>Group</Label>
          <Select
            {...register("group", {
              valueAsNumber: true,
            })}
            required
            disabled={summaryMode}
          >
            <option value="" disabled selected>
              Select a group
            </option>
            {groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroupContainer>
          <FormGroup>
            <Label>Team 1</Label>
            <TeamSelect
              {...register("team_1", {
                valueAsNumber: true,
              })}
              required
              disabled={summaryMode}
            >
              <option value="" disabled selected>
                Select a team
              </option>
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </TeamSelect>
          </FormGroup>
          <FormGroup>
            <Label>Runs</Label>
            <Input
              type="number"
              {...register("team_1_score.runs", {
                valueAsNumber: true,
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label>Overs</Label>
            <Input
              step="0.1"
              type="number"
              {...register("team_1_score.overs", {
                valueAsNumber: true,
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label>Wickets</Label>
            <Input
              type="number"
              {...register("team_1_score.wickets", {
                valueAsNumber: true,
              })}
            />
          </FormGroup>
        </FormGroupContainer>
        <FormGroupContainer>
          <FormGroup>
            <Label>Team 2</Label>
            <TeamSelect
              {...register("team_2", {
                valueAsNumber: true,
              })}
              required
              disabled={summaryMode}
            >
              <option value="" disabled>
                Select a team
              </option>
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </TeamSelect>
          </FormGroup>
          <FormGroup>
            <Label>Runs</Label>
            <Input
              type="number"
              {...register("team_2_score.runs", {
                valueAsNumber: true,
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label>Overs</Label>
            <Input
              type="number"
              step="0.1"
              {...register("team_2_score.overs", {
                valueAsNumber: true,
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label>Wickets</Label>
            <Input
              type="number"
              {...register("team_2_score.wickets", {
                valueAsNumber: true,
              })}
            />
          </FormGroup>
        </FormGroupContainer>
        {team1 && team2 && team1 === team2 ? (
          <ErrorMessage>Team 1 and Team 2 cannot be the same.</ErrorMessage>
        ) : (
          ""
        )}
        <FormGroupContainer>
          <FormGroup>
            <Label>Target</Label>
            <Input
              type="number"
              {...register("meta.target", {
                valueAsNumber: true,
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label>Max overs</Label>
            <Input
              type="number"
              step="0.1"
              {...register("meta.max_overs", {
                valueAsNumber: true,
              })}
            />
          </FormGroup>
        </FormGroupContainer>
        <FormGroupContainer>
          <FormGroup>
            <Label>Toss Winner</Label>
            <Select
              {...register("meta.toss", {
                valueAsNumber: true,
              })}
            >
              <option value="" disabled selected>
                Select a team
              </option>
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Label>Bat First</Label>
            <Select
              {...register("meta.bat_first", {
                valueAsNumber: true,
              })}
            >
              <option value="" disabled selected>
                Select a team
              </option>
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Label>Match Winner</Label>
            <Select
              {...register("winner", {
                valueAsNumber: true,
              })}
            >
              <option value="" disabled selected>
                Select a team
              </option>
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </Select>
          </FormGroup>
        </FormGroupContainer>
        <FormGroupContainer>
          <FormGroup>
            <Label>Match Status</Label>
            <Select {...register("status")}>
              <option value="COMPLETED">Completed</option>
              <option value="UP_COMING" selected>
                Up Coming
              </option>
            </Select>
          </FormGroup>
          <FormGroup>
            <Label>Result</Label>
            <Select {...register("result")}>
              <option value="RESULT">Result</option>
              <option value="DRAW">Draw</option>
              <option value="NO_RESULT">No Result</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="date">Match Date:</Label>
            <Input
              id="date"
              type="date"
              {...register("date", { required: true })}
            />
          </FormGroup>
        </FormGroupContainer>
        <FormGroup>
          <SubmitButton type="submit" disabled={team1 === team2}>
            {selectedMatch ? "Update Match" : "Add Match"}
          </SubmitButton>
          {selectedMatch && <Button onClick={handleCancel}>Cancel</Button>}
        </FormGroup>
      </MatchStyledForm>
    </>
  );
};

export default MatchForm;
