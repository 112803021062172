import styled from "styled-components";


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  padding: 0 20px 0 0;
`;

export const ActionButton = styled.button`
  background-color: ${(props) => (props.primary ? "#007bff" : "#6c757d")};
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  &:hover {
    opacity: 0.8;
  }
`;