import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import MatchForm from "./MatchForm";
import MatchSummary from "./MatchSummary";
import {
  selectEditMode,
  selectedMatch,
  selectMatch,
  getMatchesByGroup,
  selectMatchSummaryMode,
} from "./matchSlice";

import {
  ActionButton,
  ButtonContainer,
} from "../../components/CustomComponents/Buttons";
import { useParams } from "react-router-dom";

const MatchGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  padding: 20px;
`;

const MatchList = ({
  groupId,
  mode = "edit",
  handleOnChangeSliceIndex,
  selectedMatchIds = [],
}) => {
  const currentMatch = useSelector(selectedMatch);
  const editMode = useSelector(selectEditMode);
  const matches = useSelector((state) => getMatchesByGroup(state, groupId));
  const summaryMode = useSelector(selectMatchSummaryMode);

  const dispatch = useDispatch();

  const handleCreateNewMatch = () => {
    dispatch(selectMatch(null));
  };

  return (
    <>
      {editMode ? (
        <MatchForm selectedMatch={currentMatch} />
      ) : (
        <>
          {summaryMode === "edit" ? (
            <ButtonContainer>
              <ActionButton primary onClick={handleCreateNewMatch}>
                Create New Match
              </ActionButton>
            </ButtonContainer>
          ) : (
            ""
          )}
          <MatchGrid>
            {matches.map((d, index, array) => (
              <MatchSummary
                key={d.id}
                match={d}
                matchNumber={index}
                handleOnChangeSliceIndex={handleOnChangeSliceIndex}
                totalMatches={array.length}
                mode={mode}
                selectedMatchIds={selectedMatchIds}
              />
            ))}
          </MatchGrid>
        </>
      )}
    </>
  );
};

export default MatchList;
