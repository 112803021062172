class CricketPointsCalculator {
  POINTS_PER_WIN = 2;
  POINTS_PER_NRT = 1;
  POINTS_PER_LOSS = 0;
  BALLS_PER_OVER = 6;
  OVERS_PER_INNING = 20;
  WICKETS_PER_INNING = 10;

  constructor(settings) {
    var newSettings = settings.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );

    this.POINTS_PER_WIN = newSettings["points_per_win"];
    this.POINTS_PER_NRT = newSettings["points_per_nrt"];
    this.POINTS_PER_LOSS = newSettings["points_per_loss"];
    this.BALLS_PER_OVER = newSettings["balls_per_over"];
    this.OVERS_PER_INNING = newSettings["overs_per_inning"];
    this.WICKETS_PER_INNING = newSettings["wickets_per_inning"];
  }

  getPoints(matches, index) {
    let newPoints = [];

    const uniqueTeams = matches.reduce((accumulator, current) => {
      accumulator.add(current.team_1);
      accumulator.add(current.team_2);
      return accumulator;
    }, new Set());

    const uniqueTeamsArray = Array.from(uniqueTeams);

    newPoints = uniqueTeamsArray.map((teamId) => ({
      id: teamId,
      team: teamId,
      played: 0,
      won: 0,
      loss: 0,
      points: 0,
      nrt: 0,
      for: { runs: 0, overs: 0 },
      against: { runs: 0, overs: 0 },
      nrr: 0,
    }));

    const matchesList =
      index !== undefined ? matches.filter((x) => x.index <= index) : matches;
    const selectedMatchIds = matchesList.map((match) => match.id);

    matchesList.forEach((match) => {
      const {
        team_1,
        team_2,
        team_1_score,
        team_2_score,
        winner,
        result,
        status,
      } = match;

      if (status === "COMPLETED") {
        newPoints = this.addPoints(
          team_1,
          team_1_score,
          result,
          winner,
          newPoints,
          team_2_score
        );
        newPoints = this.addPoints(
          team_2,
          team_2_score,
          result,
          winner,
          newPoints,
          team_1_score
        );
      }
    });

    newPoints = newPoints.map((pointEntry) => ({
      ...pointEntry,
      nrr: this.calculateNRR(pointEntry.for, pointEntry.against),
    }));

    newPoints.sort(
      (a, b) => b.points - a.points || b.nrr - a.nrr || a.played - b.played
    );

    return { newPoints, selectedMatchIds };
  }

  calculateNRR = (forData, againstData) => {
    const { runs: forRuns, overs: forOvers } = forData;
    const { runs: againstRuns, overs: againstOvers } = againstData;

    if (forOvers === 0 || againstOvers === 0) {
      return 0;
    }

    const forOversParts = forOvers.toFixed(1).toString().split(".");
    const againstOversParts = againstOvers.toFixed(1).toString().split(".");
    const forPart =
      forRuns /
      (parseInt(forOversParts[0]) +
        parseFloat(forOversParts[1] / this.BALLS_PER_OVER));
    const againstPart =
      againstRuns /
      (parseInt(againstOversParts[0]) +
        parseFloat(againstOversParts[1] / this.BALLS_PER_OVER));

    const nrr = forPart - againstPart ? forPart - againstPart : 0;

    return nrr;
  };

  addPoints = (team, team_1_score, result, winner, points, team_2_score) => {
    const id = team;
    const name = team;

    const { runs, overs, wickets } =
      result !== "NO_RESULT" ? team_1_score : { runs: 0, overs: 0, wickets: 0 };

    const {
      runs: otherTeamRuns,
      overs: otherTeamOvers,
      wickets: otherTeamWickets,
    } = result !== "NO_RESULT" ? team_2_score : { runs: 0, overs: 0, wickets: 0 };

    const teamIndex = points.findIndex((x) => x.id === id);
    const won = result === "RESULT" && id === winner ? 1 : 0;
    const loss = result === "RESULT" && id !== winner ? 1 : 0;
    const nrt = result === "DRAW" || result === "NO_RESULT" ? 1 : 0;

    const pointPerMatch =
      won * this.POINTS_PER_WIN +
      loss * this.POINTS_PER_LOSS +
      nrt * this.POINTS_PER_NRT;

    if (teamIndex < 0) {
      points = [
        ...points,
        {
          id: id,
          team: name,
          played: 1,
          won: id === winner ? 1 : 0,
          loss: id !== winner ? 1 : 0,
          nrt: nrt,
          points: pointPerMatch,
          for: {
            runs: runs,
            overs:
              wickets === this.WICKETS_PER_INNING
                ? this.OVERS_PER_INNING
                : overs,
          },
          against: {
            runs: otherTeamRuns,
            overs:
              otherTeamWickets === this.WICKETS_PER_INNING
                ? this.OVERS_PER_INNING
                : otherTeamOvers,
          },
        },
      ];
    } else {
      const teamCurrentPoints = points.find((x) => x.id === id);
      const newTeamPoints = {
        ...teamCurrentPoints,
        played: (teamCurrentPoints.played += 1),
        won: (teamCurrentPoints.won += won),
        loss: (teamCurrentPoints.loss += loss),
        nrt: (teamCurrentPoints.nrt += nrt),
        points: (teamCurrentPoints.points += pointPerMatch),
        for: {
          runs: (teamCurrentPoints.for.runs += runs),
          overs: this.overCalculate(
            teamCurrentPoints.for.overs,
            wickets === this.WICKETS_PER_INNING ? this.OVERS_PER_INNING : overs
          ),
        },
        against: {
          runs: (teamCurrentPoints.against.runs += otherTeamRuns),
          overs: this.overCalculate(
            teamCurrentPoints.against.overs,
            otherTeamWickets === this.WICKETS_PER_INNING
              ? this.OVERS_PER_INNING
              : otherTeamOvers
          ),
        },
      };
      
      points = [
        ...points.slice(0, teamIndex),
        newTeamPoints,
        ...points.slice(teamIndex + 1),
      ];
    }
    return points;
  };

  overCalculate = (currentVal, newVal) => {
    const currentValParts = currentVal.toFixed(1).toString().split(".");
    const newValParts = newVal.toFixed(1).toString().split(".");

    const balls = parseInt(currentValParts[1]) + parseInt(newValParts[1]);
    const overs = parseInt(currentValParts[0]) + parseInt(newValParts[0]);
    const sum =
      overs +
      parseInt(balls / this.BALLS_PER_OVER) +
      (balls % this.BALLS_PER_OVER) * 0.1;

    return sum;
  };
}

export default CricketPointsCalculator;
