import { Cricket } from "./sports/Cricket";

class PointsCalculator {
  constructor(tournamentType, matches, settings) {
    this.type = tournamentType;

    this.matches = matches;
    this.settings = settings;
  }

  _getUniqueTeams() {
    const uniqueTeams = this.matches.reduce((accumulator, current) => {
      accumulator.add(current.team_1);
      accumulator.add(current.team_2);
      return accumulator;
    }, new Set());

    return Array.from(uniqueTeams);
  }

  getPoints(index) {
    const matchesList =
      index !== undefined
        ? this.matches.filter((x) => x.index <= index)
        : this.matches;
    const selectedMatchIds = matchesList.map((match) => match.id);

    const teams = this._getUniqueTeams();

    const pointCalculator = new Cricket(teams, matchesList, this.settings);

    const initialPointTable = pointCalculator.generateInitialPointTable();

    let currentPointTable = [...initialPointTable];

    this.matches.forEach((match) => {
      const {
        team_1,
        team_2,
        team_1_score,
        team_2_score,
        winner,
        result,
        status,
        meta,
      } = match;

      const team1Index = currentPointTable.findIndex(
        (team) => team.id === team_1
      );
      const team2Index = currentPointTable.findIndex(
        (team) => team.id === team_2
      );

      const currentTeam1Points = currentPointTable[team1Index];
      const currentTeam2Points = currentPointTable[team2Index];

      if (status === "COMPLETED") {
        const newTeam1Points = pointCalculator.addTeamPoints(
          team_1,
          team_1_score,
          team_2,
          team_2_score,
          result,
          winner,
          meta,
          currentTeam1Points
        );

        currentPointTable = [
          ...currentPointTable.slice(0, team1Index),
          newTeam1Points,
          ...currentPointTable.slice(team1Index + 1),
        ];

        const newTeam2Points = pointCalculator.addTeamPoints(
          team_2,
          team_2_score,
          team_1,
          team_1_score,
          result,
          winner,
          meta,
          currentTeam2Points
        );

        currentPointTable = [
          ...currentPointTable.slice(0, team2Index),
          newTeam2Points,
          ...currentPointTable.slice(team2Index + 1),
        ];
      }
    });

    const computeFieldCalculatedPointTable =
      pointCalculator.generateComputedFields(currentPointTable);

    const sortedPointTable = pointCalculator.sortPointTable(
      computeFieldCalculatedPointTable
    );

    return {
      newPoints: sortedPointTable,
      selectedMatchIds,
    };
  }
}

export default PointsCalculator;
