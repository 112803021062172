export const setTokens = ({ access, refresh }) => {
  sessionStorage.setItem("access", access);
  sessionStorage.setItem("refesh", refresh);
};

export const getTokens = () => {
  const access = sessionStorage.getItem("access");
  const refresh = sessionStorage.getItem("access");
  return { access, refresh };
};

export const clearTokens = () => {
  sessionStorage.clear();
};
