import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTeam, editTeam, deleteTeam, selectTeams } from "./teamSlice";
import styled from "styled-components";
import { Table, Button, Icon } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import {
  FormGroup,
  Input,
  Label,
  StyledForm,
  SubmitButton,
} from "../../components/CustomForm";
import { useParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  width: 50%;
`;

const FormContainer = styled.div`
  width: 50%;
  margin-left: 20px;
`;

const TeamTable = ({ teams, onEdit, selectedTeam }) => {
  const dispatch = useDispatch();

  const handleDeleteTeam = (teamId) => {
    dispatch(deleteTeam({ teamId }));
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {teams.map((team) => (
          <Table.Row key={team.id} active={team.id === selectedTeam?.id}>
            <Table.Cell>{team.name}</Table.Cell>
            <Table.Cell>
              <Button icon color="green" onClick={() => onEdit(team)}>
                <Icon name="edit" />
              </Button>
              <Button
                icon
                color="red"
                onClick={() => handleDeleteTeam(team.id)}
              >
                <Icon name="trash" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const TeamForm = ({ selectedTeam, onEdit, teams }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();

  const { id } = useParams();

  useEffect(() => {
    if (selectedTeam) {
      reset({ name: selectedTeam.name });
    }
  }, [selectedTeam, reset]);

  const onSubmit = ({ name }) => {
    if (selectedTeam) {
      dispatch(
        editTeam({
          teamId: selectedTeam.id,
          updatedTeam: {
            id: selectedTeam.id,
            name: name,
          },
        })
      );
      onEdit(null);
    } else {
      dispatch(
        createTeam({
          team: {
            id: Math.random(),
            name: name,
          },
        })
      );
    }
    reset({ name: "" });
  };

  const handleCancel = () => {
    onEdit(null);
    reset({ name: "" });
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>Name</Label>
          <Input {...register("name")} />
        </FormGroup>
        <FormGroup>
          <SubmitButton type="submit">
            {selectedTeam ? "Update Team" : "Add Team"}
          </SubmitButton>
          {selectedTeam && <Button onClick={handleCancel}>Cancel</Button>}
        </FormGroup>
      </StyledForm>
    </>
  );
};

const TeamManager = () => {
  const teams = useSelector(selectTeams);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const handleEditTeam = (team) => {
    setSelectedTeam(team);
  };

  return (
    <>
      <Container>
        <TableContainer>
          <TeamTable
            teams={teams}
            onEdit={handleEditTeam}
            selectedTeam={selectedTeam}
          />
        </TableContainer>
        <FormContainer>
          <TeamForm
            selectedTeam={selectedTeam}
            onEdit={setSelectedTeam}
            teams={teams}
          />
        </FormContainer>
      </Container>
    </>
  );
};

export default TeamManager;
