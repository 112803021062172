import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicApi } from "../../api/api";

export const checkUserLoggedIn = createAsyncThunk(
  "auth/checkUserLoggedIn",
  async (_, { dispatch }) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      dispatch(updateLoginState({ accessToken, refreshToken }));
    } else {
      dispatch(logout());
    }
    return { accessToken, refreshToken };
  }
);

export const attemptLogin = createAsyncThunk(
  "auth/attemptLogin",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await publicApi.post("/auth/login/", userData);
      const { access: accessToken, refresh: refreshToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      return { accessToken, refreshToken };
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          error: "Something went wrong. Please try again later.",
        });
      }
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
    refreshToken: null,
    isLoggedIn: false,
    error: "",
  },
  reducers: {
    updateLoginState: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(attemptLogin.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.loading = false;
        state.error = null;
      })
      .addCase(attemptLogin.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.accessToken = null;
        state.refreshToken = null;
        state.loading = false;
        state.error = action.payload?.error || "Failed to log in.";
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addCase(checkUserLoggedIn.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.isLoggedIn = !!(action.payload.accessToken && action.payload.refreshToken);
      });
  },
});

export const { updateLoginState, logout: logoutUser } = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectError = (state) => state.auth.error;

export default authSlice.reducer;
