import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

export const FormTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-left: 1rem;
  flex: 1;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;
export const Option = styled.option`
  padding: 0.5rem;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 0.25rem;
`;

export const SubmitButton = styled.button`
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;


export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const CheckboxLabel = styled.label`
  margin-left: 0.5rem;
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;

  &:checked {
    background-color: #007bff;
    border-color: #007bff;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 25%;
    left: 30%;
  }
`;

