import { createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "./api";

const TOURNAMENT_END_POINT = `/tournaments`;

export const fetchTournamentById = createAsyncThunk(
  "api/fetchTournamentById",
  async ({ id, isPrivate = true }, { rejectWithValue }) => {
    const url = isPrivate
      ? `${TOURNAMENT_END_POINT}/${id}/details/`
      : `${TOURNAMENT_END_POINT}/${id}/details/public/`;
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          error: "Something went wrong. Please try again later.",
        });
      }
    }
  }
);

export const createTournament = createAsyncThunk(
  "api/createTournament",
  async ({ tournamentData }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${TOURNAMENT_END_POINT}/`,
        tournamentData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          error: "Something went wrong. Please try again later.",
        });
      }
    }
  }
);

export const editTournament = createAsyncThunk(
  "api/editTournament",
  async ({ id, tournamentData }, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `${TOURNAMENT_END_POINT}/${id}/`,
        tournamentData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          error: "Something went wrong. Please try again later.",
        });
      }
    }
  }
);
