import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { fetchTournamentById } from "../../api/apiActions";

const TOURNAMENT_END_POINT = `/tournaments`;

export const getMatchesByTournamentId = createAsyncThunk(
  "match/getMatchesByTournamentId",
  async (tournamentId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `${TOURNAMENT_END_POINT}/${tournamentId}/matches/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveMatchesByTournamentId = createAsyncThunk(
  "match/saveMatchesByTournamentId",
  async ({ tournamentId, matches }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${TOURNAMENT_END_POINT}/${tournamentId}/matches/`,
        matches
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const matchSlice = createSlice({
  name: "match",
  initialState: {
    matches: [],
    status: "idle",
    error: null,
    currentMatch: null,
    editMode: false,
    matchSummaryMode: "edit",
  },
  reducers: {
    createMatch: (state, action) => {
      const { match } = action.payload;
      state.matches.push(match);
      state.editMode = false;
    },
    editMatch: (state, action) => {
      const { matchId, updatedMatch } = action.payload;
      const matchIndex = state.matches.findIndex(
        (match) => match.id === matchId
      );
      state.matches[matchIndex] = updatedMatch;
      state.editMode = false;
    },
    selectMatch: (state, action) => {
      const matchId = action.payload;
      const matchIndex = state.matches.findIndex(
        (match) => match.id === matchId
      );
      state.currentMatch = state.matches[matchIndex];
      state.editMode = true;
    },
    deleteMatch: (state, action) => {
      const matchId = action.payload;
      state.matches = state.matches.filter((match) => match.id !== matchId);
    },
    setMatchSummaryMode: (state, action) => {
      const mode = action.payload;
      state.matchSummaryMode = mode;
    },
    setEditMode: (state, action) => {
      const mode = action.payload;
      state.editMode = mode;
    },
  },
  extraReducers: {
    [getMatchesByTournamentId.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [getMatchesByTournamentId.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.matches = action.payload;
    },
    [getMatchesByTournamentId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [saveMatchesByTournamentId.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [saveMatchesByTournamentId.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.matches = action.payload;
    },
    [saveMatchesByTournamentId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchTournamentById.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchTournamentById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { matches } = action.payload;
      state.matches = matches;
    },
    [fetchTournamentById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const {
  createMatch,
  editMatch,
  deleteMatch,
  selectMatch,
  setMatchSummaryMode,
  setEditMode
} = matchSlice.actions;
export const selectMatches = (state) => state.match.matches;
export const getMatchesByGroup = (state, groupId) => {
  if (groupId) {
    return state.match.matches.filter((match) => match.group === groupId);
  }
  return state.match.matches;
};
export const selectedMatch = (state) => state.match.currentMatch;
export const selectEditMode = (state) => state.match.editMode;
export const selectMatchSummaryMode = (state) => state.match.matchSummaryMode;
export default matchSlice.reducer;
