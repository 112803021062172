// import { Link } from "react-router-dom";

import styled from "styled-components";
import { Button } from "semantic-ui-react";

// import TournamentsList from "./features/tournament/TournamentsList";
import { useNavigate } from "react-router-dom";
import TournamentList from "../components/TournamentList";
function LandingPage() {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Description>
          Hello,
          <br />
          My name is Nipuna.
          <br />
          This is a pilot Project to track Sports Point tables. Feel free to try
          exisitng tournaments or create your own. Currently only supports for
          Cricket. but I'm hoping to add more games in future. If you have any
          question or ideas feel free to reach me out.
          <br />
          Thank you.
          <a target="_blank" href="https://www.nipsaga.com/">
            My Website
          </a>
        </Description>
        <TournamentList/>
        {/* <TournamentsList />
        <Link to="/tournament">
          <Button color="white" background="#f6581f" style={{fontSize:'20px'}}>
            Create New
          </Button>
        </Link> */}
        {/* <Link to="/tournament">
          <Button
            color="youtube"
            background="#f6581f"
            style={{ fontSize: "20px" }}
          >
            Tournaments
          </Button>
        </Link> */}
        <Button onClick={() => navigate("/login")}>Login</Button>
      </Container>
    </>
  );
}

export default LandingPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2px;
  background-color:#0B2447;
  width:100vw;
  height:100vh;
`;

const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2em;
  padding: 2em;
  background: white;
  font-size: 1.5em;
`;
