import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";

import CricketPointsCalculator from "../../utils/pointCalculator";
import { selectTeams } from "../../features/team/teamSlice";
import MatchList from "../../features/match/MatchList";
import PointTable from "./PointTable";
import { getGroupById } from "../../features/group/groupSlice";
import PointsCalculator from "../../point_calculator/PointCalculator";
import { selectCurrentTournament } from "../../features/tournament/tournamentSlice";

const se = [
  { key: "points_per_win", value: 2, label: "Points Per Win" },
  { key: "points_per_nrt", value: 1, label: "Points Per No Result" },
  { key: "points_per_loss", value: 0, label: "Points Per Loss" },
  { key: "balls_per_over", value: 6, label: "Balls Per Over" },
  { key: "overs_per_inning", value: 20, label: "Overs Per Inning" },
  { key: "wickets_per_inning", value: 10, label: "Wickets Per Inning" },
];

const GroupSummary = ({ group, matchesList = [], settings = se }) => {
  const [matches, setMatches] = useState([]);
  const [selectedMatchIds, setSelectedMatchIds] = useState([]);
  const [points, setPoints] = useState([]);
  const [sliceIndex, setSliceIndex] = useState();
  const teams = useSelector(selectTeams);
  const groupName = useSelector((state) => getGroupById(state, group))?.name;
  const selectedTournament = useSelector(selectCurrentTournament);

  useEffect(() => {
    const matches = matchesList.map((match, index) => ({
      ...match,
      index,
      selected: false,
    }));

    setMatches(matches);
  }, [matchesList]);

  useEffect(() => {
    if (settings.length) {
      const cricketPointCalculator = new CricketPointsCalculator(settings);

      // const { newPoints: points, selectedMatchIds } =
      //   cricketPointCalculator.getPoints(matches, sliceIndex);

      const PointsCalculatorObj = new PointsCalculator(
        selectedTournament.type,
        matches,
        settings
      );
      const { newPoints: points, selectedMatchIds } =PointsCalculatorObj.getPoints(sliceIndex);

      const newPoints = points.map((entry) => ({
        ...entry,
      }));
      setSelectedMatchIds(selectedMatchIds);
      setPoints(newPoints);
    }
  }, [matches, sliceIndex, teams, settings]);

  const handleOnChangeSliceIndex = (index) => {
    const newMatches = matches.map((match) => ({
      ...match,
      selected: match.index <= index,
    }));

    setMatches(newMatches);
    setSliceIndex(index);
  };

  return (
    <>
      <Container>
        <h3>{groupName}</h3>
        <PointTable type="cricket" rows={points} />
      </Container>
      <MatchList
        mode="view"
        groupId={group}
        handleOnChangeSliceIndex={handleOnChangeSliceIndex}
        selectedMatchIds={selectedMatchIds}
      />
    </>
  );
};

export default GroupSummary;

const Container = styled.div`
  width: 95%;
  margin: 2rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
