import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createGroup,
  editGroup,
  deleteGroup,
  selectGroups,
  // saveGroupsByTournamentId,
} from "./groupSlice";
import styled from "styled-components";
import { Table, Button, Icon } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import {
  FormGroup,
  Input,
  Label,
  StyledForm,
  SubmitButton,
} from "../../components/CustomForm";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  width: 50%;
`;

const FormContainer = styled.div`
  width: 50%;
  margin-left: 20px;
`;

const GroupTable = ({ groups, onEdit, selectedGroup }) => {
  const dispatch = useDispatch();

  const handleDeleteGroup = (groupId) => {
    dispatch(deleteGroup({ groupId }));
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {groups.map((group) => (
          <Table.Row key={group.id} active={group.id === selectedGroup?.id}>
            <Table.Cell>{group.name}</Table.Cell>
            <Table.Cell>
              <Button icon color="green" onClick={() => onEdit(group)}>
                <Icon name="edit" />
              </Button>
              <Button
                icon
                color="red"
                onClick={() => handleDeleteGroup(group.id)}
              >
                <Icon name="trash" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const GroupForm = ({ selectedGroup, onEdit, groups }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (selectedGroup) {
      reset({ name: selectedGroup.name });
    }
  }, [selectedGroup, reset]);

  const onSubmit = ({ name }) => {
    if (selectedGroup) {
      dispatch(
        editGroup({
          groupId: selectedGroup.id,
          updatedGroup: {
            id: selectedGroup.id,
            name: name,
          },
        })
      );
      onEdit(null);
    } else {
      dispatch(
        createGroup({
          group: {
            id: Math.random(),
            name: name,
          },
        })
      );
    }
    reset({ name: "" });
  };

  const handleCancel = () => {
    onEdit(null);
    reset({ name: "" });
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>Name</Label>
          <Input {...register("name")} />
        </FormGroup>
        <FormGroup>
          <SubmitButton type="submit">
            {selectedGroup ? "Update Group" : "Add Group"}
          </SubmitButton>
          {selectedGroup && <Button onClick={handleCancel}>Cancel</Button>}
        </FormGroup>
      </StyledForm>
    </>
  );
};

const GroupManager = () => {
  const groups = useSelector(selectGroups);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleEditGroup = (group) => {
    setSelectedGroup(group);
  };

  return (
    <>
      <Container>
        <TableContainer>
          <GroupTable
            groups={groups}
            onEdit={handleEditGroup}
            selectedGroup={selectedGroup}
          />
        </TableContainer>
        <FormContainer>
          <GroupForm
            selectedGroup={selectedGroup}
            onEdit={setSelectedGroup}
            groups={groups}
          />
        </FormContainer>
      </Container>
    </>
  );
};

export default GroupManager;
