import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  fetchTournaments,
  selectTournaments,
} from "../features/tournament/tournamentSlice";

const Container = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  flex-direction:column;
`;

const Item = styled.li`
  background-color: white;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const Title = styled.h3`
  margin: 0;
  padding: 1rem;
`;

const LinkWrapper = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
  &:hover {
    background-color: #f5f5f5;
  }
`;

function TournamentList() {
  const dispatch = useDispatch();
  const tournaments = useSelector(selectTournaments);

  useEffect(() => {
    dispatch(fetchTournaments({ page: 1, per_page: 5 }));
  }, [dispatch]);

  return (
    <Container>
      {tournaments.map((tournament) => (
        <Item key={tournament.id}>
          <LinkWrapper to={`/tournament/${tournament.uuid}`}>
            <Title>{tournament.name}</Title>
          </LinkWrapper>
        </Item>
      ))}
    </Container>
  );
}

export default TournamentList;
