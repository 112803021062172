import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import GroupSummary from "../components/GroupOverview/GroupSummary";

import {
  selectMatches,
  setEditMode,
  setMatchSummaryMode,
} from "../features/match/matchSlice";

import {
  selectCurrentTournament,
  selectGetByIdStatus,
  selectGetByIdError,
} from "../features/tournament/tournamentSlice";

const PointTableContainer = styled.div`
  width: 90%;
  margin-top: 2em;
`;

const TournamentView = () => {
  const dispatch = useDispatch();
  const selectedTournament = useSelector(selectCurrentTournament);
  const status = useSelector(selectGetByIdStatus);
  const error = useSelector(selectGetByIdError);

  const matches = useSelector(selectMatches);

  const [matchesByGroup, setMatchesByGroup] = useState({});

  const { id } = useParams();

  useEffect(() => {
    dispatch(setMatchSummaryMode("view"));
    dispatch(setEditMode(false));
  }, [dispatch, id]);

  useEffect(() => {
    const _MatchesByGroup = matches.reduce((acc, item) => {
      const groupKey = item.group;
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    }, {});

    setMatchesByGroup(_MatchesByGroup);
  }, [matches]);

  if (status === "loading" || status == "idle") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>{error}</div>;
  }

  return (
    <>
      <h1>{selectedTournament.name}</h1>
      <PointTableContainer>
        {Object.entries(matchesByGroup).map(([groupId, groupMatches]) => (
          <GroupSummary
            key={groupId}
            group={parseInt(groupId)}
            matchesList={groupMatches}
            settings={Object.entries(selectedTournament.settings).map(
              ([key, val]) => ({ key: key, value: val })
            )}
          />
        ))}
      </PointTableContainer>
    </>
  );
};

export default TournamentView;
