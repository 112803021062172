import React, { useState } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout } from "../../features/auth/authSlice";
import { clearTokens } from "../../utils/tokenService";
import styled from "styled-components";

const Dashboard = () => {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const path = location.pathname.split("/")[1]; // Get the first part of the path

  const handleLogout = () => {
    dispatch(logout());
    clearTokens();
    navigate("/");
  };

  return (
    <DashboardWrapper>
      <Topbar>
        <Logo>
          <img src="logo192.png" alt="Logo" />
        </Logo>
        <LogoutButtonWrapper>
          <LogoutButton onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            Logout
          </LogoutButton>
        </LogoutButtonWrapper>
        <ToggleNavButton onClick={() => setIsNavOpen(!isNavOpen)}>
          <FontAwesomeIcon icon={faBars} />
        </ToggleNavButton>
      </Topbar>
      <Sidebar open={isNavOpen}>
        <Nav>
          <NavItem>
            <StyledNavLink
              to={`/${path}`}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              end
            >
              Dashboard
            </StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink
              to={`/${path}/tournament`}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              Tournament
            </StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink
              to={`/${path}/profile`}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              Profile
            </StyledNavLink>
          </NavItem>
          {/* <NavItem>
            <StyledNavLink
              to={`/${path}/settings`}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              Settings
            </StyledNavLink>
          </NavItem> */}
        </Nav>
      </Sidebar>
      <Content>
        <Outlet />
      </Content>
    </DashboardWrapper>
  );
};

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Topbar = styled.nav`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 64px;
  padding: 0 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Logo = styled.div`
  flex: 1;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const LogoutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 16px;

  /* Add this media query */
  @media (max-width: 768px) {
    margin-top: 32px;
  }
`;

export const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #ff6b6b;
  }

  svg {
    margin-right: 8px;
  }
`;

export const ToggleNavButton = styled.button`
  display: none;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: #333333;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: ${({ open }) => (open ? "240px" : "0")};
  transition: width 0.2s;
  overflow-x: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 64px;
  z-index: 0;

  /* Add this media query */
  @media (max-width: 768px) {
    position: static;
    width: 100%;
  }
`;

export const Nav = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  /* Add this media query */
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 32px;
  }
`;

export const NavItem = styled.li`
  list-style: none;
  margin-bottom: 16px;

  /* Add this media query */
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s;

  &.pending {
    color: gray;
  }

  &.active {
    color: #ff6b6b;
    font-weight: 700;
  }
`;

export const Content = styled.main`
  flex: 1;
  margin-left: 240px;
  padding: 24px;
  height: 100%;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export default Dashboard;
