import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  mode: "display",
  settings: [],
  status: "idle",
};

export const fetchSettings = createAsyncThunk(
  "setting/fetctSettings",
  async (id) => {
    try {
      const response = await axios.get(`/data/${id}/settings.json`);
      return [...response.data];
    } catch (error) {
      return error.message;
    }
  }
);

export const InitSettings = createAsyncThunk(
  "setting/initSettings",
  async (id) => {
    try {
      const response = await axios.get(`/data/settings.json`);
      return [...response.data];
    } catch (error) {
      return error.message;
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    settingChanged: {
      reducer: (state, action) => {
        state.settings = action.payload;
        state.mode = "display";
      },
    },

    modeChanged(state, action) {
      state.mode = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSettings.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.settings = action.payload;
      })
      .addCase(InitSettings.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(InitSettings.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.settings = action.payload;
      });
  },
});

export const getSettingsStatus = (state) => state.settings.status;

export const selectAllSettings = (state) => state.settings.settings;
export const selectMode = (state) => state.settings.mode;

export const { settingChanged, modeChanged } = settingsSlice.actions;

export default settingsSlice.reducer;
