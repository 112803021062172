import React, { useState } from "react";

import { Accordion, Icon } from "semantic-ui-react";
import styled from "styled-components";
import GroupManager from "../group/GroupManager";
import MatchList from "../match/MatchList";
import TeamManager from "../team/TeamManager";

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
`;

const ExtendedTournamentForm = ({ isEditMode }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (e, { index }) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <AccordionContainer>
      <Accordion styled exclusive={false} fluid>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleAccordionClick}
        >
          <Icon name="dropdown" />
          Groups
        </Accordion.Title>

        <Accordion.Content active={activeIndex === 0}>
          <GroupManager />
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleAccordionClick}
        >
          <Icon name="dropdown" />
          Teams
        </Accordion.Title>

        <Accordion.Content active={activeIndex === 1}>
          <TeamManager />
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={handleAccordionClick}
        >
          <Icon name="dropdown" />
          Matches
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <MatchList />
        </Accordion.Content>
      </Accordion>
    </AccordionContainer>
  );
};

export default ExtendedTournamentForm;
