import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchTournamentById } from "../../api/apiActions";
import TournamentView from "../../components/TournamentView";

const TournamentViewManager = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchTournamentById({id}));
  }, [dispatch, id]);

  return <TournamentView />;
};

export default TournamentViewManager;
