import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { attemptLogin, selectError, selectIsLoggedIn } from "./authSlice";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  FormContainer,
  FormTitle,
  StyledForm,
  Label,
  Input,
  SubmitButton,
  FormGroup,
} from "../../components/CustomForm";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-image: url(./balls_img.png);
  background-size: cover;
  background-position: center;
  background-blur: 5px;
  position: relative;
  width: 100vw;
  height: 100vh;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 1;
  }
`;
const StyledFormContainer = styled(FormContainer)`
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  z-index: 2;
`;

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const errorMessage = useSelector(selectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || "/welcome/tournament/";

  useEffect(() => {
    if (isLoggedIn) {
      navigate(from);
    }
  }, [isLoggedIn, navigate]);

  const onSubmit = (data) => {
    dispatch(attemptLogin(data));
  };

  return (
    <Container>
      <StyledFormContainer>
        <FormTitle>Login</FormTitle>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Email:</Label>
            <Input type="email" {...register("email")} />
            {errors.email && <p>{errors.email.message}</p>}
          </FormGroup>
          <FormGroup>
            <Label>Password:</Label>
            <Input type="password" {...register("password")} />
            {errors.password && <p>{errors.password.message}</p>}
          </FormGroup>
          {errorMessage && <p>{errorMessage}</p>}
          <SubmitButton type="submit">Login</SubmitButton>
        </StyledForm>
      </StyledFormContainer>
    </Container>
  );
};

export default LoginPage;
