import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import {
  fetchTournamentById,
  createTournament,
  editTournament,
} from "../../api/apiActions";

const initialState = {
  tournaments: [],
  currentTournament: null,
  totalPages: 1,
  fetchStatus: "idle",
  fetchError: null,
  getByIdStatus: "idle",
  getByIdError: null,
  createStatus: "idle",
  createError: null,
  editStatus: "idle",
  editError: null,
  deleteStatus: "idle",
  deleteError: null,
};

const TOURNAMENT_END_POINT = `/tournaments`;

export const fetchTournaments = createAsyncThunk(
  "tournament/fetchTournaments",
  async ({ page = 1, per_page = 10 } = {}, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `${TOURNAMENT_END_POINT}?page=${page}&per_page=${per_page}`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          error: "Something went wrong. Please try again later.",
        });
      }
    }
  }
);

export const deleteTournament = createAsyncThunk(
  "tournament/deleteTournament",
  async (id, { rejectWithValue }) => {
    try {
      await api.delete(`${TOURNAMENT_END_POINT}/${id}/`);
      return id;
    } catch (error) {
      if (error.response) {
        throw new Error('Failed to fetch user data.');
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          error: "Something went wrong. Please try again later.",
        });
      }
    }
  }
);

const tournamentSlice = createSlice({
  name: "tournament",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTournaments.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(fetchTournaments.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.tournaments = action.payload.tournaments;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchTournaments.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.payload;
      })
      .addCase(deleteTournament.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(deleteTournament.fulfilled, (state, action) => {
        state.deleteStatus = "succeeded";
        const index = state.tournaments.findIndex(
          (tournament) => tournament.id === action.payload
        );
        state.tournaments.splice(index, 1);
      })
      .addCase(deleteTournament.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.deleteError = action.payload;
      })
      .addCase(fetchTournamentById.pending, (state) => {
        state.getByIdStatus = "loading";
      })
      .addCase(fetchTournamentById.fulfilled, (state, action) => {
        state.getByIdStatus = "succeeded";
        const { groups, teams, matches, ...tournamentData } = action.payload;
        state.currentTournament = tournamentData;
      })
      .addCase(fetchTournamentById.rejected, (state, action) => {
        state.getByIdStatus = "failed";
        state.getByIdError = action.payload;
      })
      .addCase(createTournament.pending, (state) => {
        state.createStatus = "loading";
      })
      .addCase(createTournament.fulfilled, (state, action) => {
        state.createStatus = "succeeded";
        const { groups, teams, matches, ...tournamentData } = action.payload;
        state.tournaments.push(tournamentData);
      })
      .addCase(createTournament.rejected, (state, action) => {
        state.createStatus = "failed";
        state.createError = action.payload;
      })
      .addCase(editTournament.pending, (state) => {
        state.editStatus = "loading";
      })
      .addCase(editTournament.fulfilled, (state, action) => {
        state.editStatus = "succeeded";
        const { groups, teams, matches, ...tournamentData } = action.payload;
        const index = state.tournaments.findIndex(
          (tournament) => tournament.id === tournamentData.id
        );
        state.tournaments[index] = tournamentData;
      })
      .addCase(editTournament.rejected, (state, action) => {
        state.editStatus = "failed";
        state.editError = action.payload;
      });
  },
});

// Selectors
export const selectTournaments = (state) => state.tournament.tournaments;
export const selectCurrentTournament = (state) =>
  state.tournament.currentTournament;
export const selectTotalPages = (state) => state.tournament.totalPages;
export const selectFetchStatus = (state) => state.tournament.fetchStatus;
export const selectFetchError = (state) => state.tournament.fetchError;
export const selectGetByIdStatus = (state) => state.tournament.getByIdStatus;
export const selectGetByIdError = (state) => state.tournament.getByIdError;
export const selectCreateStatus = (state) => state.tournament.createStatus;
export const selectCreateError = (state) => state.tournament.createError;
export const selectEditStatus = (state) => state.tournament.editStatus;
export const selectEditError = (state) => state.tournament.editError;
export const selectDeleteStatus = (state) => state.tournament.deleteStatus;
export const selectDeleteError = (state) => state.tournament.deleteError;

export default tournamentSlice.reducer;
