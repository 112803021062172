import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { fetchTournamentById } from "../../api/apiActions";

const TOURNAMENT_END_POINT = `/tournaments`;

export const getGroupsByTournamentId = createAsyncThunk(
  "group/getGroupsByTournamentId",
  async (tournamentId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `${TOURNAMENT_END_POINT}/${tournamentId}/groups/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveGroupsByTournamentId = createAsyncThunk(
  "group/saveGroupsByTournamentId",
  async ({ tournamentId, groups }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${TOURNAMENT_END_POINT}/${tournamentId}/groups/`,
        groups
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const groupSlice = createSlice({
  name: "group",
  initialState: {
    groups: [],
    status: "idle",
    error: null,
  },
  reducers: {
    createGroup: (state, action) => {
      const { group } = action.payload;
      state.groups.push(group);
    },
    editGroup: (state, action) => {
      const { groupId, updatedGroup } = action.payload;
      const groupIndex = state.groups.findIndex(
        (group) => group.id === groupId
      );
      state.groups[groupIndex] = updatedGroup;
    },
    deleteGroup: (state, action) => {
      const { groupId } = action.payload;
      state.groups = state.groups.filter((group) => group.id !== groupId);
    },
  },
  extraReducers: {
    [getGroupsByTournamentId.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [getGroupsByTournamentId.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.groups = action.payload;
    },
    [getGroupsByTournamentId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [saveGroupsByTournamentId.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [saveGroupsByTournamentId.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.groups = action.payload;
    },
    [saveGroupsByTournamentId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchTournamentById.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchTournamentById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { groups } = action.payload;
      state.groups = groups;
    },
    [fetchTournamentById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const { createGroup, editGroup, deleteGroup } =
  groupSlice.actions;
export const selectGroups = (state) => state.group.groups;
export const getGroupById = (state, groupId) => {
  return state.group.groups.find((group) => group.id === groupId);
};
export default groupSlice.reducer;
