import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import RequiredAuth from "./features/auth/RequiredAuth";
import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/Profile";
import Tournament from "./pages/Tournament";
import TournamentForm from "./features/tournament/TournamentForm";
import LoginPage from "./features/auth/LoginPage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkUserLoggedIn } from "./features/auth/authSlice";
import TournamentViewManager from "./features/tournament/TournamentViewManager";
import PublicTournamentView from "./pages/PublicTournamentView";


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserLoggedIn());
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<LandingPage />} />

          <Route path="login" element={<LoginPage />} />
          <Route path="tournament">
            <Route path=":id" element={<PublicTournamentView />} />
          </Route>
          <Route element={<RequiredAuth />}>
            <Route path="welcome" element={<Dashboard />}>
              <Route path="profile" element={<Profile />} />
              <Route path="tournament">
                <Route path="" element={<Tournament />} />
                <Route path="create" element={<TournamentForm />} />
                <Route path=":id/edit" element={<TournamentForm />} />
                <Route path=":id" element={<TournamentViewManager />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const Layout = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100vw",
        minHeight: "100vh",
      }}
    >
      <Outlet />
    </div>
  );
};

const NoPage = () => {
  return <div>No Page</div>;
};

export default App;
