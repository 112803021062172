import React from "react";

import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useSelector } from "react-redux";

import { selectIsLoggedIn } from "./authSlice";

const RequiredAuth = () => {
  const IsLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();

  return IsLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequiredAuth;
