import React from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { getTeamById } from "../../features/team/teamSlice";

const PointTable = ({ type, rows = [] }) => {
  const columns = ["Team", "P", "W", "L", "NR/TIED", "Pts", "F", "A", "NRR"];

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          {columns.map((column, index) => (
            <Table.HeaderCell key={index}>{column}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map((row) => (
          <Table.Row key={row.id}>
            <TeamNameCell id={row.id} />
            <Table.Cell>{row.played}</Table.Cell>
            <Table.Cell>{row.won}</Table.Cell>
            <Table.Cell>{row.loss}</Table.Cell>
            <Table.Cell>{row.nrt}</Table.Cell>
            <Table.Cell>{row.points}</Table.Cell>
            <Table.Cell>
              {row.for?.runs}/{row.for?.overs}
            </Table.Cell>
            <Table.Cell>
              {row.against?.runs}/{row.against?.overs}
            </Table.Cell>
            <Table.Cell>{row.nrr.toFixed(3)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default PointTable;

const TeamNameCell = ({ id }) => {
  const name = useSelector((state) => getTeamById(state, id))?.name;

  return <Table.Cell>{name}</Table.Cell>;
};
