import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { selectCurrentTournament } from "./tournamentSlice";
import styled from "styled-components";

import { Message } from "semantic-ui-react";
import ExtendedTournamentForm from "./ExtendedTournamentForm";
import {
  selectMatches,
  setEditMode,
  setMatchSummaryMode,
} from "../match/matchSlice";
import {
  fetchTournamentById,
  createTournament,
  editTournament,
} from "../../api/apiActions";
import { selectTeams } from "../team/teamSlice";
import { selectGroups } from "../group/groupSlice";
import {
  FormGroup,
  Label,
  FormTitle,
  FormContainer,
  Input,
  Select,
  Option,
  ErrorMessage,
  SubmitButton,
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel,
} from "../../components/CustomForm";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 70%;
`;

const PanelContainer = styled.div`
  &:first-child {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
  }

  &:first-child > div {
    flex-grow: 1;
  }
`;

const Panel = styled.div``;

const defaultValues = {
  type: "CR",
  is_public: false,
  name: "",
};

function TournamentForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentTournament = useSelector(selectCurrentTournament);
  const teams = useSelector(selectTeams);
  const groups = useSelector(selectGroups);
  const matches = useSelector(selectMatches);
  const isEditMode = !!id;

  const [error, setError] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const tournamentType = watch("type");

  useEffect(() => {
    if (isEditMode) {
      dispatch(fetchTournamentById({ id }));
      dispatch(setMatchSummaryMode("edit"));
      dispatch(setEditMode(false));
    }
  }, [dispatch, id, isEditMode]);

  useEffect(() => {
    if (isEditMode && currentTournament) {
      setValue("name", currentTournament.name);
      setValue("type", currentTournament.type);
      setValue("is_public", currentTournament.is_public);
      Object.entries(currentTournament.settings || {}).forEach(
        ([key, value]) => {
          setValue(`settings.${key}`, value);
        }
      );
    }
  }, [isEditMode, currentTournament, setValue]);

  const onSubmit = async (data) => {
    if (isEditMode) {
      const tournamentData = {
        ...data,
        teams,
        groups,
        matches,
      };
      try {
        const result = await dispatch(editTournament({ id, tournamentData }));

        if (result.error) {
          // show error message to user
          setError(result.payload.status?.[0]);
        } else {
          navigate("/welcome/tournament");
        }
      } catch (error) {
        // handle the error thrown during dispatch or processing
        console.error("An error occurred:", error);
        setError("An unexpected error occurred. Please try again.");
      }
    } else {
      const tournamentData = {
        ...data,
        teams,
        groups,
        matches,
      };

      try {
        const result = await dispatch(createTournament({ tournamentData }));

        if (result.error) {
          // show error message to user
          setError(result.payload.status?.[0]);
        } else {
          navigate("/welcome/tournament");
        }
      } catch (error) {
        // handle the error thrown during dispatch or processing
        console.error("An error occurred:", error);
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  

  return (
    <>
      <FormContainer>
        <FormTitle>
          {isEditMode ? "Edit Tournament" : "Create Tournament"}
        </FormTitle>
        {error && (
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>{error}</p>
          </Message>
        )}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <PanelContainer>
            <Panel>
              <FormGroup>
                <Label htmlFor="name">Tournament Name:</Label>
                <Input
                  id="name"
                  type="text"
                  {...register("name", { required: true })}
                />
                {errors.name && <ErrorMessage>Name is required</ErrorMessage>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="type">Tournament Type:</Label>
                <Select
                  id="type"
                  {...register("type", { required: true })}
                  defaultValue="CR"
                >
                  <Option value="CR">Cricket</Option>
                  <Option value="SC">Soccer</Option>
                </Select>
                {errors.type && <ErrorMessage>Type is required</ErrorMessage>}
              </FormGroup>
              <FormGroup>
                <CheckboxContainer>
                  <CheckboxInput id="is_public" {...register("is_public")} />
                  <CheckboxLabel htmlFor="is_public">Is Public</CheckboxLabel>
                </CheckboxContainer>
              </FormGroup>
            </Panel>
            <Panel>
              {SETTINGS[tournamentType]?.map((item) => (
                <FormGroup key={item.key}>
                  <Label htmlFor={item.key}>{item.label}</Label>
                  <Input
                    type="number"
                    id={`settings.${item.key}`}
                    {...register(`settings.${item.key}`, {
                      valueAsNumber: true,
                    })}
                    defaultValue={item.value}
                  />
                </FormGroup>
              ))}
            </Panel>
          </PanelContainer>
          <SubmitButton type="submit">
            {isEditMode ? "Update Tournament" : "Create Tournament"}
          </SubmitButton>
        </StyledForm>
      </FormContainer>
      {<ExtendedTournamentForm isEditMode={isEditMode} />}
    </>
  );
}

export default TournamentForm;

const SETTINGS = {
  CR: [
    { key: "points_per_win", value: 2, label: "Points Per Win" },
    { key: "points_per_nrt", value: 1, label: "Points Per No Result" },
    { key: "points_per_loss", value: 0, label: "Points Per Loss" },
    { key: "balls_per_over", value: 6, label: "Balls Per Over" },
    { key: "overs_per_inning", value: 20, label: "Overs Per Inning" },
    { key: "wickets_per_inning", value: 10, label: "Wickets Per Inning" },
  ],
  SC: [
    { key: "points_per_win", value: 2, label: "Points Per Win" },
    { key: "points_per_nrt", value: 1, label: "Points Per No Result" },
    { key: "points_per_loss", value: 0, label: "Points Per Loss" },
  ],
};
