import axios from "axios";

const BASEURL = process.env.REACT_APP_BACKEND_URL;

export const publicApi = axios.create({
  baseURL: BASEURL,
});

export const api = axios.create({
  baseURL: BASEURL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refresh_token = localStorage.getItem("refreshToken");

      try {
        const res = await axios.post(`${BASEURL}/auth/refresh/`, {
          refresh: refresh_token,
        });

        if (res.status === 200) {
          const new_access_token = res.data.access;
          localStorage.setItem("accessToken", new_access_token);
          
          // Update axios instance with the new access token
          api.defaults.headers.common["Authorization"] = `Bearer ${new_access_token}`;

          // Retry the original request
          return api(originalRequest);
        }
      } catch (err) {
     
        if (err.response && err.response.status === 400) {
          // Refresh token is expired, clear local storage and log out
          localStorage.clear();
          // Redirect to the login page or log out programmatically
          window.location.href = "/login"; // Replace with your login page URL
        } else {
          return Promise.reject(err);
        }
      }
    }
    return Promise.reject(error);
  }
);


