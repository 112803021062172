import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getTeamById } from "../team/teamSlice";
import { deleteMatch, selectMatch, selectMatchSummaryMode } from "./matchSlice";

const MatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: ${(props) =>
    props.isSelected
      ? "0px 6px 6px rgba(235, 102, 54, 0.3)"
      : "0px 2px 6px rgba(0, 0, 0, 0.1)"};
  padding: 1rem;

  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
`;

const TitleText = styled.p`
  font-size: 0.8rem;
`;
const TeamContainer = styled.div``;
const TeamLineStyle = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => (props.isLoser ? "gray" : "inherit")};
  & > * {
    color: inherit;
  }
`;
const TeamInfo = styled.div`
  color: inherit;
`;

const ScoreLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 2px;
`;

const ScoreItem = styled.div`
  margin-right: 2px;
  color: inherit;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const Button = styled.button`
  border: none;
  border-radius: 3px;
  padding: 6px 10px;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  margin: 2px;
`;

const DeleteButton = styled(Button)`
  background-color: #ff5a5a;
  color: white;
`;

const EditButton = styled(Button)`
  background-color: #4caf50;
  color: white;
`;

const SelectButton = styled(Button)`
  background-color: #007bff;
  color: white;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MatchSummary = ({
  match,
  matchNumber,
  totalMatches,
  handleOnChangeSliceIndex,
  selectedMatchIds,
}) => {
  const {
    id,
    team_1,
    team_1_score,
    team_2,
    team_2_score,
    winner,
    status,
    date,
    result_str,
  } = match;
  const dispatch = useDispatch();
  const team1 = useSelector((state) => getTeamById(state, team_1));
  const team2 = useSelector((state) => getTeamById(state, team_2));
  const summaryMode = useSelector(selectMatchSummaryMode);

  return (
    <MatchContainer isSelected={selectedMatchIds.includes(id)}>
      <TitleContainer>
        <TitleText>{date}</TitleText>{" "}
        <TitleText>
          {" "}
          {matchNumber + 1} of {totalMatches}
        </TitleText>
      </TitleContainer>
      <TeamContainer>
        <TeamLine
          status={status}
          team={team1}
          score={team_1_score}
          winner={winner}
        />
        <TeamLine
          status={status}
          team={team2}
          score={team_2_score}
          winner={winner}
        />
      </TeamContainer>
      <Footer>
        <div>{result_str}</div>
        <ButtonContainer>
          <EditButton onClick={() => dispatch(selectMatch(id))}>
            Edit
          </EditButton>
          {summaryMode === "edit" ? (
            <DeleteButton onClick={() => dispatch(deleteMatch(id))}>
              Delete
            </DeleteButton>
          ) : (
            <SelectButton onClick={() => handleOnChangeSliceIndex(matchNumber)}>
              Select
            </SelectButton>
          )}
        </ButtonContainer>
      </Footer>
    </MatchContainer>
  );
};

export default MatchSummary;

const TeamLine = ({ status, team, score, winner }) => {
  const isLoser = team.id !== winner && status === "COMPLETED";

  return (
    <TeamLineStyle isLoser={isLoser}>
      <TeamInfo>{team?.name}</TeamInfo>
      <ScoreLine>
        <ScoreItem>
          {score.runs}/{score.wickets}
        </ScoreItem>
        <ScoreItem>({score.overs})</ScoreItem>
      </ScoreLine>
    </TeamLineStyle>
  );
};
